import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import { Link, graphql } from "gatsby";
import HikakuTable from "components/hikakuTableLoadable";
import Socials from "components/socials";
import BenchMark from 'components/cpu/cinebench';
import CpuMatsubi from 'components/cpu/cpu_matsubi';
import HowtoFilter from 'components/cpu/howtofilter';
import TableDescription from 'components/cpu/table_description';
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "cpu/cinebench-r23"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    allCinebenchR23Json {
      nodes {
        name
        c23m
        c23s
        year
        clock
        core
        thread
        tdp
        price
        cospa
      }
    },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Cinebench 2024 スコア表`}</h1>
    <time itemProp="datePublished" dateTime="Fri Mar 28 2025 21:37:13 GMT+0900 (日本標準時)">更新日:2025年3月28日</time>
    <Socials {...props} mdxType="Socials" />
    <p>{`CinebenchはドイツのMaxon社が提供するベンチマークソフトウェアであり、3D映像編集ソフトCINEMA 4D上での`}<strong parentName="p"><em parentName="strong">{`レンダリング能力を主に計測`}</em></strong>{`している。`}</p>
    <p>{`映像編集におけるレンダリングとは、物体表面の拡散反射や透明なマテリアルの屈折率などを考慮して光の計算を行い、画像の各ピクセル毎の色や透明度を計算する非常に時間のかかる作業であり、レンダリング速度は作業効率に直結する。`}</p>
    <p>{`本ページでは2020年11月に公開された`}<strong parentName="p"><em parentName="strong">{`Cinebench R23のベンチマークスコアを一覧できる性能比較表`}</em></strong>{`を用意した。(最新の`}<Link to="/cpu/cinebench2024/" mdxType="Link">{`Cinebench 2024`}</Link>{`はこちらを参照。)`}</p>
    <p>{`レンダリング能力のテストであるものの、`}<strong parentName="p"><em parentName="strong">{`クリエイティブなワークの他、一般的なCPUの性能を測る上での指標として広く普及`}</em></strong>{`している。`}</p>
    <p>{`より多くのCPUを調べたい人は`}<Link to="/cpu/" mdxType="Link">{`CPU性能比較表`}</Link>{`を参考にして欲しい。こちらは総合的なCPUの能力を測るベンチ―マークであるPASSMARKで一覧表を作成している。`}</p>
    <BenchMark c23 mdxType="BenchMark" />
    <CpuMatsubi mdxType="CpuMatsubi" />
    <TableDescription hidePass cinebench mdxType="TableDescription" />
    <HowtoFilter mdxType="HowtoFilter" />
    <p>{`比較表の見方として、`}<strong parentName="p"><em parentName="strong">{`C_R23(M)`}</em>{`は`}<em parentName="strong">{`Cinebench R23 Multi-Core Score`}</em></strong>{`で全てのCoreを使用したときのベンチマーク結果、`}<strong parentName="p"><em parentName="strong">{`C_R23(S)`}</em>{`は`}<em parentName="strong">{`Cinebench R23 Single-Core Score`}</em></strong>{`で単一のCPU Coreのみを使用したときのスコアを表す。`}</p>
    <p>{`アプリケーションの起動など、多くの場合パソコンの快適さはシングルコアの性能に依存するところがあるため、`}<strong parentName="p"><em parentName="strong">{`多目的で使うならばシングルとマルチのスコアバランスが良いCPUをおすすめ`}</em></strong>{`する。`}</p>
    <HikakuTable {...props} checkboxes={['corei', 'ryzen', 'celeron', 'notedesk']} cpuData={props.data.allCinebenchR23Json.nodes} sort={{
      fieldName: 'c23m'
    }} fieldArray={[{
      id: 'name',
      placeholder: '例:i5-9,i5-8',
      filterType: 'string',
      css: {
        width: '130px'
      }
    }, {
      id: 'c23m',
      placeholder: '例:>10000'
    }, {
      id: 'c23s'
    }, {
      id: 'tdp'
    }, {
      id: 'core'
    }, {
      id: 'thread'
    }, {
      id: 'clock'
    }, {
      id: 'price'
    }, {
      id: 'year'
    }, {
      id: 'cospa'
    }]} mdxType="HikakuTable" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      